
import { computed, ref, onMounted } from 'vue'

export default {
  setup () {
    const currentTime = ref<number>(0)
    const targetTime = ref<Date>(new Date())

    const calculateNextSunday = (): Date => {
      const now = new Date()
      const dayOfWeek = now.getDay()
      const daysUntilSunday = (7 - dayOfWeek) % 7
      // Создаем дату следующего воскресенья в 10:00 утра
      const nextSunday = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + daysUntilSunday,
        10,
        0,
        0
      )

      return nextSunday
    }

    const updateTime = () => {
      targetTime.value = calculateNextSunday()
      currentTime.value = targetTime.value.getTime() - new Date().getTime()
    }

    onMounted(() => {
      targetTime.value = calculateNextSunday()
      updateTime()
      setInterval(updateTime, 1000)
    })

    const days = computed(() => Math.floor(currentTime.value / (1000 * 60 * 60 * 24)))
    const hours = computed(() => Math.floor((currentTime.value / (1000 * 60 * 60)) % 24))
    const minutes = computed(() => Math.floor((currentTime.value / (1000 * 60)) % 60))
    const seconds = computed(() => Math.floor((currentTime.value / 1000) % 60))

    const formatTime = (value: number) => (value < 10 ? '0' + value : value.toString())

    return {
      days,
      hours,
      minutes,
      seconds,
      formatTime
    }
  }
}
